export const imports = {
  'src/pages/001-landing/landing.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-001-landing-landing" */ 'src/pages/001-landing/landing.mdx'
    ),
  'src/pages/001-landing/why-learn-sql.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-001-landing-why-learn-sql" */ 'src/pages/001-landing/why-learn-sql.mdx'
    ),
  'src/pages/002-intro/introduction.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-002-intro-introduction" */ 'src/pages/002-intro/introduction.mdx'
    ),
  'src/pages/003-about/about-this-page.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-003-about-about-this-page" */ 'src/pages/003-about/about-this-page.mdx'
    ),
  'src/pages/004-start/getting-started.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-004-start-getting-started" */ 'src/pages/004-start/getting-started.mdx'
    ),
  'src/pages/005-demo/creating-the-demo-database.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-005-demo-creating-the-demo-database" */ 'src/pages/005-demo/creating-the-demo-database.mdx'
    ),
  'src/pages/006-concepts/concepts-and-semantics.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-006-concepts-concepts-and-semantics" */ 'src/pages/006-concepts/concepts-and-semantics.mdx'
    ),
  'src/pages/007-db-objects/database-objects.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-007-db-objects-database-objects" */ 'src/pages/007-db-objects/database-objects.mdx'
    ),
  'src/pages/008-types/data-types.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-008-types-data-types" */ 'src/pages/008-types/data-types.mdx'
    ),
  'src/pages/009-dql/data-query-language.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-009-dql-data-query-language" */ 'src/pages/009-dql/data-query-language.mdx'
    ),
  'src/pages/010-dml/data-manipulation-language.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-010-dml-data-manipulation-language" */ 'src/pages/010-dml/data-manipulation-language.mdx'
    ),
  'src/pages/011-ddl/data-definition-language.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-011-ddl-data-definition-language" */ 'src/pages/011-ddl/data-definition-language.mdx'
    ),
  'src/pages/012-using comments/using-comments.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-012-using-comments-using-comments" */ 'src/pages/012-using comments/using-comments.mdx'
    ),
  'src/pages/015-query-exec-method/query-exec-methods.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-015-query-exec-method-query-exec-methods" */ 'src/pages/015-query-exec-method/query-exec-methods.mdx'
    ),
  'src/pages/016-reserved-words/reserved-words.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-016-reserved-words-reserved-words" */ 'src/pages/016-reserved-words/reserved-words.mdx'
    ),
  'src/pages/017-rdbms/rdbms.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-017-rdbms-rdbms" */ 'src/pages/017-rdbms/rdbms.mdx'
    ),
  'src/pages/z_Resources/resources.mdx': () =>
    import(
      /* webpackPrefetch: true, webpackChunkName: "src-pages-z-resources-resources" */ 'src/pages/z_Resources/resources.mdx'
    ),
}
